<template>
  <div class="card-cashback-bonus">
    <div v-if="imageSrc" class="card-cashback-bonus__image">
      <atomic-picture :src="imageSrc" notLazy />
    </div>

    <div class="card-cashback-bonus__container">
      <div class="card-cashback-bonus__content">
        <div class="card-cashback-bonus__title">{{ props.bonusInfo.bonusName }}</div>
        <div class="card-cashback-bonus__amount">
          <span class="card-cashback-bonus__amount-label">
            {{
              getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.cashback.amountLabel')
            }}
          </span>

          <div class="card-cashback-bonus__amount-row">
            <span class="card-cashback-bonus__amount-value">{{ bonusValue }}</span>
            <span class="card-cashback-bonus__amount-tag">
              {{
                getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.cashback.tagLabel')
              }}
            </span>
          </div>
        </div>
      </div>

      <div class="card-cashback-bonus__actions">
        <div v-if="props.bonusInfo.date" class="card-cashback-bonus__available">
          {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.availableTimerLabel') }}

          <bonuses-timer :expiredAt="props.bonusInfo.date" size="sm" @timeUp="bonusStore.getCashbackBonuses()" />
        </div>

        <button-base
          v-if="!props.hideButton"
          class="card-cashback-bonus__actions-info"
          type="secondary-2"
          size="sm"
          @click="openInfo"
        >
          <atomic-icon id="info" />
        </button-base>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IPlayerCashback } from '~/core/types';

  const props = defineProps<{
    bonusInfo: IPlayerCashback;
    hideButton?: boolean;
  }>();

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { getContent, formatAmount } = useProjectMethods();
  const walletStore = useWalletStore();
  const { activeAccount } = storeToRefs(walletStore);
  const imagesContent: { bonusId: string; image: string }[] = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.bonuses?.cashback?.bonusImages
    : globalComponentsContent?.bonuses?.cashback?.bonusImages || [];

  const defaultImageContent = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.bonuses?.cashback?.defaultImage
    : globalComponentsContent?.bonuses?.cashback?.defaultImage;

  const imageSrc = computed(() => {
    const findImage = imagesContent.find(imageData => imageData.bonusId === props.bonusInfo.bonusId);
    return findImage?.image || defaultImageContent;
  });

  const bonusValue = computed(() => {
    return formatAmount(activeAccount.value?.currency, props.bonusInfo.amount);
  });

  const isLoyaltyCashback = computed(() => {
    const cashbackContent = defaultLocaleGlobalComponentsContent
      ? defaultLocaleGlobalComponentsContent.loyalty?.cashbackList
      : globalComponentsContent?.loyalty?.cashbackList;

    if (cashbackContent) {
      return cashbackContent.some(contentCashback => contentCashback.bonusId === props.bonusInfo.bonusId);
    }

    return false;
  });

  const bonusStore = useBonusStore();
  const { openModal } = useModalStore();
  const openInfo = async (): Promise<void> => {
    await openModal(isLoyaltyCashback.value ? 'loyalty-cashback-details' : 'cashback-bonus-details', {
      props: { bonusInfo: props.bonusInfo },
    });
  };
</script>

<style src="~/assets/styles/components/card/cashback-bonus.scss" lang="scss" />
